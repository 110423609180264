import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="220709" />
      <div className="text-center">
        <h2 className="font-title-sub">Days with international food</h2>
        <p>Foreign food changes your ordinary dishes a bit different and look fresh. I always like trying new food or reproducing food I had before while traveling abroad. (Will the time come when we can cross overseas like before...)</p>

        <h3 className="font-title-sub pt-5">Taiwanese breakfast (for lunch)</h3>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/friedbread.jpg"
          width={600}
          quality={95}
        />
        <p>When I visited KALDI (international grocery store) last time, found black bean soy milk made in Taiwan. So we decided to buy ones and deep fried bread together for lunch, like traditional Taiwanese breakfast style!</p>
        <p>The taste of soy milk (very Asian, I mean not Japanese one) made me feel like having breakfast in a street in Taiwan.</p>

        <h3 className="font-title-sub pt-5">Chimaek!</h3>
        <p>I just wanted to say the word "Chimaek(치맥)", chicken and beer in Korean. I visited a korean chicken shop newly opened around the station with my little sister. We had chicken boxes, a set of chickens and cheese balls for each box.</p>
        <p>We should have ordered beers as well for chimeak but happened to find korean soda, soda in can-shaped transparent plastic bottles, arranged in front of the casher, so we had them instead.</p>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/korean-chicken.jpg"
          width={600}
          quality={95}
        />
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/korean-soda.jpg"
          width={400}
          quality={95}
        />
      </div>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
